import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, H4, Image, P } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
import { CreateAccount, DoNotAccount, EmailAddress, ForgotPassword, Href, Password, RememberPassword, SignIn, SignInAccount, SignInWith } from "../../utils/Constant";
import { useState } from "react";
import { toast } from "react-toastify";
import { createClient } from '@supabase/supabase-js';
import { getValidUserOrganization } from "../../utils/supabase";
import { decapitalizeString } from "../../utils/helper/utilities";
import { supabaseClient } from "../../utils/supabaseclient";

const has_session = localStorage.getItem('sb-huioktgvbjnujbsjtlhs-auth-token');

const Login = () => {
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [orgId, setOrgId] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        const { data, error } = await supabaseClient.auth.signInWithPassword({ email, password });
        const user = data.user
        
        const user_organization = await getValidUserOrganization(user?.email, orgId);

        if (user_organization) {
         
            supabaseClient.auth.getSession().then(({ data: { session} }) => {
              if (session) {
                console.log('session:', JSON.stringify(session));
                localStorage.setItem('session', JSON.stringify(session));
                localStorage.setItem('orgId', orgId);
              }
            });

            navigate(`${process.env.PUBLIC_URL}/schemabuilder/list`);

          } else {
            await supabaseClient.auth.signOut();
            localStorage.removeItem("session");
            localStorage.removeItem("orgId");
          }
  
      } catch (error:any) {
        console.error('Login error:', error?.message);
        setError('Invalid login credentials');
      }
    };
  

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              
              <div className="login-main">
                <Form className="theme-form" onSubmit={(e) => handleLogin(e)} >
                  <H3>Login</H3>
                  
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input type="email" required placeholder="Email" value={email} name="email" onChange={(event) => setEmail(event.target.value)}  />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Input type={show ? "text" : "password"} placeholder="*********"  onChange={(event) => setPassword(event.target.value)} value={password} name="password"/>
                      <div className="show-hide" onClick={() => setShow(!show)}>
                        <span className="show"> </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Arc XP Org Id</Label>
                    <Input required placeholder="Arc XP Org Id" value={orgId} name="orgId" onChange={(event) => setOrgId(event.target.value)}  />
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                   
                    <div className="text-end mt-3">
                      <Btn color="primary" block  className="w-100" >
                        {SignIn}
                      </Btn>
                    </div>
                  </FormGroup>
                  <div className="login-divider"></div>
                  <Link to={`${process.env.PUBLIC_URL}/authentication/forget_password`}  >
                      {ForgotPassword}
                    </Link>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
