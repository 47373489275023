import React from 'react';

interface RenderJsonProps {
  json: any;
}

const RenderJson: React.FC<RenderJsonProps> = ({ json }) => {
  const renderValue = (value: any) => {
    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return <td>{value.toString()}</td>;
    } else if (Array.isArray(value)) {
      return (
        <td>
          <ul>
            {value.map((item, index) => (
              <li key={index}>{item.toString()}</li>
            ))}
          </ul>
        </td>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <td>
          <RenderJson json={value} />
        </td>
      );
    } else {
      return <td>null</td>;
    }
  };

  return (
    <table  style={{ borderCollapse: 'collapse', width: '100%' }}>
      <tbody>
        {Object.keys(json).map((key) => (
          <tr key={key}>
            <th style={{ textAlign: 'left', padding: '8px', background: '#f2f2f2' }}>{key}</th>
            {renderValue(json[key])}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RenderJson;
